import EmailIcon from "@mui/icons-material/Email";
import PaidIcon from "@mui/icons-material/Paid";
import InstagramIcon from "@mui/icons-material/Instagram";

import profilePic from "./assets/camresch-pink.jpeg";
import logoType from "./assets/helloTricky-logoType.svg";

function App() {
  const data = {
    name: logoType,
    image: profilePic,
    bio: "Club Juggler",
    links: [
      {
        name: "Tip me",
        url: "http://venmo.com/hellotricky/",
        icon: "venmo",
      },
      {
        name: "Book me for your event",
        url: "mailto: bookings@hellotricky.com",
        icon: "email",
      },
      {
        name: "Follow me on Instagram",
        url: "https://instagram.com/themightybearsnake",
        icon: "instagram",
      },
    ],
  };

  const loadIcon = (iconName) => {
    if (iconName === "email") return <EmailIcon />;
    if (iconName === "venmo") return <PaidIcon />;
    if (iconName === "instagram") return <InstagramIcon />;
  };

  return (
    <div className="App">
      <img
        alt={`portrait of ${data.name}`}
        src={data.image}
        className="profile-pic"
      ></img>
      <img alt={`Cam Resch`} src={data.name} className="logo-type"></img>
      <ul>
        {data.links.map((link, idx) => {
          return (
            <a
              key={`link-${idx}`}
              href={link.url}
              target="_blank"
              rel="noreferrer"
            >
              <button>
                {loadIcon(link.icon)}
                <p>{link.name}</p>
              </button>
            </a>
          );
        })}
      </ul>
    </div>
  );
}

export default App;
